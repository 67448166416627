// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `input:focus::placeholder {
  color: transparent;
}
h4 {
  font-size: large;
}
::placeholder {
  padding: 5px;
}
input {
  display: block;
}
.scanSearchButton {
  width: 100%;
  border: none;
  height: 5em;
  background: transparent;
}
`, "",{"version":3,"sources":["webpack://./src/stylesheets/search/SearchBox.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;AACA;EACE,gBAAgB;AAClB;AACA;EACE,YAAY;AACd;AACA;EACE,cAAc;AAChB;AACA;EACE,WAAW;EACX,YAAY;EACZ,WAAW;EACX,uBAAuB;AACzB","sourcesContent":["input:focus::placeholder {\n  color: transparent;\n}\nh4 {\n  font-size: large;\n}\n::placeholder {\n  padding: 5px;\n}\ninput {\n  display: block;\n}\n.scanSearchButton {\n  width: 100%;\n  border: none;\n  height: 5em;\n  background: transparent;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
