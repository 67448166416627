// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loginDiv {
  background: #ffffff;
  width: 100%;
}
.imageAlphabet {
  vertical-align: baseline;
}
.tokenError {
  padding-left: 5%;
  padding-right: 5%;
  font-size: 12px;
  font-weight: 500;
  color: red;
  text-align: center;
}
`, "",{"version":3,"sources":["webpack://./src/stylesheets/loginPage/LoginPage.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,WAAW;AACb;AACA;EACE,wBAAwB;AAC1B;AACA;EACE,gBAAgB;EAChB,iBAAiB;EACjB,eAAe;EACf,gBAAgB;EAChB,UAAU;EACV,kBAAkB;AACpB","sourcesContent":[".loginDiv {\n  background: #ffffff;\n  width: 100%;\n}\n.imageAlphabet {\n  vertical-align: baseline;\n}\n.tokenError {\n  padding-left: 5%;\n  padding-right: 5%;\n  font-size: 12px;\n  font-weight: 500;\n  color: red;\n  text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
