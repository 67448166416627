// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.commonSearchButton {
  align-items: center;
  background-color: #12DAA8;
  border: 0;
  font-family: 'Switzer-Regular', sans-serif;
  font-weight: 600 !important;
  box-sizing: border-box;
  color: #191919;
  height: 50px;
  width: 15em;
  border-radius: 5px;
  font-size: larger;
  letter-spacing: 0;
  justify-content: center;
  line-height: 0;
  max-width: 100%;
  min-width: 100px;
  padding: 3%;
  text-decoration: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  white-space: nowrap;
}
.commonSearchButton:disabled,
.commonSearchButton[disabled] {
  background-color: #9B9B9B;  
    color: #191919;
}
.commonSearchButton:active,
.commonSearchButton:hover {
  outline: 0;
}

.verificationButton {
    border: 0.10px solid #FFC107;
    background-color: #FFC107;
}

.verificationButton:active,
.verificationButton:hover {
  outline: 0;
}
`, "",{"version":3,"sources":["webpack://./src/stylesheets/Button.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,yBAAyB;EACzB,SAAS;EACT,0CAA0C;EAC1C,2BAA2B;EAC3B,sBAAsB;EACtB,cAAc;EACd,YAAY;EACZ,WAAW;EACX,kBAAkB;EAClB,iBAAiB;EACjB,iBAAiB;EACjB,uBAAuB;EACvB,cAAc;EACd,eAAe;EACf,gBAAgB;EAChB,WAAW;EACX,qBAAqB;EACrB,iBAAiB;EACjB,yBAAyB;EACzB,0BAA0B;EAC1B,mBAAmB;AACrB;AACA;;EAEE,yBAAyB;IACvB,cAAc;AAClB;AACA;;EAEE,UAAU;AACZ;;AAEA;IACI,4BAA4B;IAC5B,yBAAyB;AAC7B;;AAEA;;EAEE,UAAU;AACZ","sourcesContent":[".commonSearchButton {\n  align-items: center;\n  background-color: #12DAA8;\n  border: 0;\n  font-family: 'Switzer-Regular', sans-serif;\n  font-weight: 600 !important;\n  box-sizing: border-box;\n  color: #191919;\n  height: 50px;\n  width: 15em;\n  border-radius: 5px;\n  font-size: larger;\n  letter-spacing: 0;\n  justify-content: center;\n  line-height: 0;\n  max-width: 100%;\n  min-width: 100px;\n  padding: 3%;\n  text-decoration: none;\n  user-select: none;\n  -webkit-user-select: none;\n  touch-action: manipulation;\n  white-space: nowrap;\n}\n.commonSearchButton:disabled,\n.commonSearchButton[disabled] {\n  background-color: #9B9B9B;  \n    color: #191919;\n}\n.commonSearchButton:active,\n.commonSearchButton:hover {\n  outline: 0;\n}\n\n.verificationButton {\n    border: 0.10px solid #FFC107;\n    background-color: #FFC107;\n}\n\n.verificationButton:active,\n.verificationButton:hover {\n  outline: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
